const $ = window.$ = window.jQuery = window.jQuery.noConflict();
const App = function() {
    const $header = $('#header');
    const $navbar = $('#navigation');
    const navbarHeight = $navbar.outerHeight();

    const clamp = (val, min, max) => {
        return Math.max(0, Math.min(1, (val - min) / (max - min)))
    };

    const parallaxAnimation = () => {
        let elements = document.getElementsByClassName('parallax');
        let options = {
            orientation: 'up'
        };
        let parallax = new simpleParallax(elements, options);

        return parallax;
    };

    const animeParallax = (enable = true) => {
        let elements = document.querySelectorAll('.anime-parallax');

        elements.forEach(elm => {
            let translateFrom = elm.dataset.from ? elm.dataset.from : '-70%';
            let translateTo = elm.dataset.from ? elm.dataset.to : '-30%';

            let animation = anime({
                targets: [elm],
                autoplay: false,
                translateY: [translateFrom, translateTo],
                easing: 'linear',
            });
            
            let doPrallax = () => {
                let windowHeight = Math.max(document.documentElement.clientHeight, window.innerHeight || 0);
                let anchorTop = window.pageYOffset + elm.getBoundingClientRect().top;
                let anchorBottom = anchorTop - windowHeight + elm.clientHeight;
                let seek = clamp(window.scrollY, anchorTop, anchorBottom) * animation.duration;
                animation.seek(seek);
            };

            if(enable == true) {
                document.addEventListener('scroll', doPrallax);
                doPrallax();
            } else {
                document.addEventListener('scroll', () => {
                    elements.forEach(elm => {
                        elm.style.setProperty('transform', 'none');
                    });
                });
            }
        });
    };

    const contactForm = () => {
        let $contactForm  = $('#contact-form');

        $contactForm.submit(function() {
            let message;

            let $formAlert = $('#contact-form-alert');
            let $formAlertContainer = $('#contact-form-alert-container');

            $formAlertContainer.html('Hello World!');
            
            $.ajax({
                type: 'POST',
                url: $contactForm.attr('action'),
                data: $(this).serialize(),
                success: function(response) {
                    var res = JSON.parse(response);

                    if (res.status === true) {
                        message = res.message;
                    } else {
                        message = res.message;
                    }

                    $formAlertContainer.html(message);
                    $formAlert.modal({backdrop: true});
                }
            });
            
            return false;
        });
    };

    const customSelectDropsown = () => {
        document.querySelector('.my-custom-select-wrapper').addEventListener('click', function () {
            this.querySelector('.my-custom-select').classList.toggle('open');
        });

        for (const option of document.querySelectorAll('.custom-option')) {
            option.addEventListener('click', function() {
                if (!this.classList.contains('selected')) {
                    this.parentNode.querySelector('.custom-option.selected').classList.remove('selected');
                    this.classList.add('selected');
                    this.closest('.my-custom-select').querySelector('.my-custom-select__trigger span').textContent = this.textContent;
                }
            })
        }

        window.addEventListener('click', function (e) {
            const select = document.querySelector('.my-custom-select');

            if (!select.contains(e.target)) {
                select.classList.remove('open');
            }
        });
    };

    return {
        init: () => {
            parallaxAnimation();
            // animeParallax();
            contactForm();
            // customSelectDropsown();

            $('body').scrollspy({ target: '#navigation', offset: navbarHeight });
            $('a[rel="smooth-scroll"]').smoothScroll({offset: -(navbarHeight-10), exclude: ['.skip']});
            $('.parallax-background').parallax('cover', 0.1);

            var swiper = new Swiper('.swiper-container', {
                direction: 'horizontal',
                slidesPerView: 1,
                freeMode: false,
                freeModeFluid: false,
                grabCursor: true,
                mousewheel: false,
                navigation: {
                    nextEl: '.swiper-button-next',
                    prevEl: '.swiper-button-prev',
                },
                scrollbar: {
                    el: '.swiper-scrollbar',
                    draggable: true,
                    hide: true,
                },
                breakpoints: {
                    640: {
                        direction: 'horizontal',
                        slidesPerView: 2,
                        spaceBetween: 15,
                    },
                    768: {
                        direction: 'horizontal',
                        slidesPerView: 3,
                        spaceBetween: 30,
                    }
                }
            });

            // Load more
            $('#leadership .card-item').slice(0, 3).show();
            $('#news .card-item').slice(0, 3).show();

            if ($('#leadership .card-item:hidden').length == 0) {
                $('#leadership #load-more').hide();
            }

            if ($('#news .card-item:hidden').length == 0) {
                $('#load-more-news').hide();
            }

            $('#load-more').on('click', function (e) {
                e.preventDefault();

                $('#leadership .card-item:hidden').slice(0, 3).slideDown();

                if ($('#leadership .card-item:hidden').length == 0) {
                    $('#leadership #load-more').fadeOut('slow');
                }
            });

            $('#load-more-news').on('click', function (e) {
                e.preventDefault();

                $('#news .card-item:hidden').slice(0, 3).slideDown();

                if ($('#news .card-item:hidden').length == 0) {
                    $('#news #load-more-news').fadeOut('slow');
                }
            });

            $(window).trigger('resize').trigger('scroll');
        },
        parallaxAnimation: parallaxAnimation,
        animeParallax: animeParallax,
        contactForm: contactForm
    };
}();

$(document).ready(() => {
    App.init();
});
